<template>
  <div class="prize">
    <h3 class="page-title">心书7周年奖品</h3>
    <popup @close="posterActive = false" guide="保存图片并分享给好友" :src="poster" v-if="posterActive"/>
    <b-card v-if="loading">
      <b-card-title>
        <ske width="6em"/>
      </b-card-title>
      <b-row align-v="center">
        <b-col cols="4">
          <ske type="square" height="75%"/>
        </b-col>
        <b-col>
          <ske block width="10em"></ske>
          <ske block width="7em"></ske>
          <ske block width="5em"></ske>
          <ske width="4em"></ske>
        </b-col>
      </b-row>
    </b-card>
    <template v-else>
      <b-card v-if="redeemData.length">
        <b-card-title>兑换码（共 {{redeemData.length}} 张）</b-card-title>
        <b-row align-v="center" v-for="item in redeemData" :key="item.code">
          <b-col cols="5">
            <square :src="getProductPic(item.tid)" height="75%"/>
          </b-col>
          <b-col>
            <h5 class="mb-0">{{prizeList[item.tid].name}}兑换券</h5>
            <div>兑换码 <b>{{item.code}}</b></div>
            <div class="text-muted mb-2">
              <b-badge v-if="item.orderId" variant="primary">已下单</b-badge>
              <datetime :value="item.redeemTime" v-if="item.redeemTime" prefix="兑换于 "/>
              <datetime :value="item.expireAt" prefix="兑换截止时间 " v-else-if="item.expireAt"/>
              <span v-else>永久可用</span>
            </div>
            <h5 v-if="item.hasExpired">兑换码已过期</h5>
            <template v-else>
              <b-btn v-if="item.aid" :to="`/albums/${item.aid}`">查看作品</b-btn>
              <b-btn v-if="item.orderId" :to="'/orders/' + item.orderId">查看订单</b-btn>
              <template v-if="!item.aid">
                <b-btn variant="primary" :to="`/redeem?code=${item.code}&tid=${item.tid}`">
                  前往兑换
                </b-btn>
                <b-btn @click="showPoster(item)">
                  <fa icon="gift"/>
                  送好友
                </b-btn>
              </template>
            </template>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-else class="text-center">
        <img src="https://static.weixinshu.com/assets/images/deer/cry.png!320"
              style="width: 200px;">
        <h5>您还没有奖品呢</h5>
      </b-card>
    </template>

    <b-btn @click="$router.go(-1)" block>返回</b-btn>
  </div>
</template>

<script>
export default {
  name: 'anniversaryPrize',
  title: '心书7周年奖品',
  data() {
    return {
      loading: false,
      redeemData: [],
      posterActive: false,
      poster: '',
      products: [],
      prizeList: {
        b5album: {
          code: 'b5album',
          icon: 'https://img.xinshu.me/upload/bdeca7afb6914371a6f59b25f18ad0b1',
          name: '锁线精装照片书'
        },
        xalbum: {
          code: 'xalbum',
          icon: 'https://img.xinshu.me/upload/ad06f373e8f545a4968512acacfe7004',
          name: 'A4精装纪念册'
        },
        postcard: {
          code: 'postcard',
          icon: 'https://img.xinshu.me/upload/ecb8d5df6b184c8ca43e3cfccf4df40a',
          name: 'LOMO卡'
        },
        xcalbum: {
          code: 'xcalbum',
          icon: 'https://img.xinshu.me/upload/0f5ee965a655491f863ca9e88a3df3d2',
          name: 'A4轻奢杂志册'
        },
        'frame-moment': {
          code: 'frame-moment',
          icon: 'https://img.xinshu.me/upload/000f6e601546465e8ca6a6b501e2a373',
          name: '朋友圈相框'
        },
        'calendar-2021': {
          code: 'calendar-2021',
          icon: 'https://img.xinshu.me/upload/0a2b8d83ca4646768d9c6793900b9bae',
          name: '2021经典台历'
        },
        note: {
          code: 'note',
          icon: 'https://img.xinshu.me/upload/1b97f57e1181425aaa5b4667df39b192',
          name: '亲子笔记本'
        }
      }
    }
  },
  async mounted() {
    await this.getRedeemData()
  },
  methods: {
    async getRedeemData() {
      try {
        this.loading = true
        this.redeemData = await this.$req.get('/api/seventh_anniversary/redeem_codes')
        this.products = await this.$ajax.fetchAlbumProducts()
        this.redeemData.forEach(item => {
          if (this.$day(item.expireAt).format('HHmm') === '0000') {
            item.expireAt = new Date(item.expireAt).getTime() - 60 * 1000
          }
          if (new Date(item.expireAt) < new Date()) {
            item.hasExpired = true
          }
        })
      } finally {
        this.loading = false
      }
    },
    showPoster(value) {
      this.posterActive = true
      const query = {
        nickname: this.user.nickname,
        avatar: this.user.avatar,
        code: value.code
      }
      this.poster = 'https://canvas.xinshu.me/generate/7year.' + value.tid + this.serialize(query)
    },
    getProductPic(value) {
      const index = this.products.findIndex(i => value === i.tid)
      return this.products[index].hero
    }
  }
}
</script>

<style lang="scss" scoped>
.prize {
  max-width: 640px;
}
.row + .row {
  margin-top: $card-columns-gap;
}
</style>
